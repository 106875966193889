:root {
  /*Navy Blue*/
  --cf-navy-blue: #162d55;
  --cf-color-white: #ffffff;
  --cf-color-light-blue: #00adee;

  --cf-yellow-orange: #ff7d00;
  --cf-orange: #e85000;
  --cf-teal: #00feb7;
  --cf-green: #4caf50;

  --cf-newsromm-search-gray: #eaeaea;
  --cf-color-light-gray: #d1d3d4;
  --cf-color-black: #000000;

  --cf-color-light-blue-plus: #28abe2;
  --cf-color-light-blue-sub: #81bef7;
  --cf-bg-gray1: #bbbcbe;

  --cf-nav-bar-gray3: #a0a09f;

  --cf-font-gray1: #7b736f;
  --cf-font-blue1: #00adef;
  --cf-border-gray1: rgba(47, 46, 46, 0.21);

  --site-width1: min(100vw, 1920px);
  --site-width2: 740px;

  --cf-primary2: #0077e7;
  --cf-status-green: #4caf50;
  --cf-status-red: #f44336;
  --cf-status-amber: #ff9800;
  /*font-size*/
  --cf-slider-huge: min(6.5rem, 12.5vh);
  --cf-title-huge: 80px;
  --cf-title-supper-plus: 65px;
  --cf-title-supper: 50px;
  --cf-title-max: 32px;
  --cf-title-h1: 24px;
  --cf-title-h2: 20px;
  --cf-title-h3: 18px;
  --cf-comm-text: 20px;
  --cf-small-text: 18px;
  --cf-comm-line-height: 1.5;
  --cf-block-text: 28px;
  /*end font-size*/

  /*mobile font-size*/
  --cf-comm-text-mobile: 14px;
  --cf-mobile-text-size-large: 20px;
  --cf-mobile-text-size-medium: 18px;
  --cf-mobile-text-size-small: 16px;
  --cf-mobile-subtitle-size: 20px;
  --cf-mobile-subtitle-size-medium: 22px;
  --cf-mobile-subtitle-size-large: 24px;

  /*end mobile font-size*/
}

$cf-color-white: var(--cf-color-white);
$cf-color-light-blue: var(--cf-color-light-blue);
$cf-newsromm-search-gray: var(--cf-newsromm-search-gray);
$cf-color-light-gray: var(--cf-color-light-gray);
$cf-navy-blue: var(--cf-navy-blue);

$cf-yellow-orange: var(--cf-yellow-orange);
$cf-orange: var(--cf-orange);
$cf-teal: var(--cf-teal);
$cf-green: var(--cf-green);

$cf-color-light-blue-plus: var(--cf-color-light-blue-plus);
$cf-color-light-blue-sub: var(--cf-color-light-blue-sub);
$cf-bg-gray1: var(--cf-bg-gray1);

$cf-nav-bar-gray3: var(--cf-nav-bar-gray3);

$cf-font-gray1: var(--cf-font-gray1);
$cf-font-gray2: var(--cf-font-gray2);
$cf-font-blue1: var(--cf-font-blue1);
$cf-color-black: var(--cf-color-black);

$cf-border-gray1: var(--cf-border-gray1);

$site-width1: var(--site-width1);
$site-width2: var(--site-width2);

/*font-size*/
$cf-slider-huge: var(--cf-slider-huge);
$cf-title-huge: var(--cf-title-huge);
$cf-title-supper-plus: var(--cf-title-supper-plus);
$cf-title-supper: var(--cf-title-supper);
$cf-title-max: var(--cf-title-max);
$cf-title-h1: var(--cf-title-h1);
$cf-title-h2: var(--cf-title-h2);
$cf-title-h3: var(--cf-title-h3);
$cf-comm-text: var(--cf-comm-text);
$cf-small-text: var(--cf-small-text);
$cf-comm-line-height: var(--cf-comm-line-height);
$cf-block-text: var(--cf-block-text);
/*end font-size*/

/*mobile font-size*/
$cf-comm-text-mobile: var(--cf-comm-text-mobile);
$cf-mobile-text-size-large: var(--cf-mobile-text-size-large);
$cf-mobile-text-size-medium: var(--cf-mobile-text-size-medium);
$cf-mobile-text-size-small: var(--cf-mobile-text-size-small);
$cf-mobile-subtitle-size: var(--cf-mobile-subtitle-size);
$cf-mobile-subtitle-size-medium: var(--cf-mobile-subtitle-size-medium);
$cf-mobile-subtitle-size-large: var(--cf-mobile-subtitle-size-large);
@media screen and (max-width: 600px) {
  /*mobile and vertical display*/
}

/*end mobile font-size*/

$font_inter_path: './assets/fonts/Inter/';
$font_bevietnampro_path: './assets/fonts/BeVietnamPro/';

@font-face {
  font-family: 'Be Vietnam Pro Medium';
  src: url($font_bevietnampro_path + 'BeVietnamPro-Medium.otf');
}

@font-face {
  font-family: 'Be Vietnam Pro ExtraBold';
  src: url($font_bevietnampro_path + 'BeVietnamPro-ExtraBold.otf');
}

@font-face {
  font-family: 'Inter Bold';
  src: url($font_inter_path + 'Inter-Bold-4.otf');
}

@font-face {
  font-family: 'Inter Medium';
  src: url($font_inter_path + 'Inter-Medium-8.otf');
}

@font-face {
  font-family: 'Inter Light';
  src: url($font_inter_path + 'Inter-Light-7.otf');
}

@font-face {
  font-family: 'Inter SemiBold';
  src: url($font_inter_path + 'Inter-SemiBold-10.otf');
}

@font-face {
  font-family: 'Inter Regular';
  src: url($font_inter_path + 'Inter-Regular-9.otf');
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/materialicons/MaterialIcons.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

body {
  padding: 0;
  margin: 0 auto;
  font-family: 'Inter Light';
  font-size: $cf-comm-text;
  background-color: $cf-navy-blue;
  overflow-x: hidden;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

::ng-deep .mat-big-tooltip {
  transform: scale(3) !important;
}

::ng-deep .mat-medium-tooltip {
  transform: scale(2) !important;
}

::ng-deep .mat-spinner {
  circle {
    stroke: $cf-color-light-blue !important;
  }
}

::ng-deep .mat-expansion-panel-header {
  border-radius: 0;
}

::ng-deep .mat-expansion-panel-header-title {
  font-family: 'Inter Bold';
  font-size: $cf-mobile-text-size-medium;
}

// toaster styles
.toast-success {
  background-color: var(--cf-status-green) !important;
}

.toast-error {
  background-color: var(--cf-status-red) !important;
}

.toast-info {
  background-color: var(--cf-primary2) !important;
}

.toast-error {
  background-color: var(--cf-status-amber) !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  /*default link*/
  &:link,
  &:visited {
    text-decoration: none;
    color: $cf-color-white;
  }

  &:hover {
    text-decoration: none;
    color: $cf-color-light-blue;
  }

  /*end default link*/

  /*navy blue link*/
  &.blue {
    color: $cf-navy-blue;

    &:hover {
      color: $cf-color-light-blue;
    }
  }

  &.light-blue {
    color: $cf-color-light-blue;

    &:hover {
      color: $cf-navy-blue;
    }
  }

  &.light-blue-white {
    color: $cf-color-light-blue;

    &:hover {
      color: $cf-color-white;
    }
  }

  &.black {
    color: $cf-color-black;

    &:hover {
      color: $cf-color-light-blue;
    }
  }

  &.gray {
    color: $cf-color-light-gray;

    &:hover {
      color: $cf-color-light-blue;
    }
  }

  /*end navy blue link*/
}

h1,
h2,
h3,
h4,
h5 {
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 500;
}

h1,
h3 {
  font-family: 'Be Vietnam Pro Medium';
}

h2 {
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: 'Be Vietnam Pro ExtraBold';
  text-transform: uppercase;
}

h4 {
  font-family: 'Inter Bold';
  text-transform: uppercase;
}

h5 {
  font-family: 'Inter Medium';
  text-transform: uppercase;
}

.paragraph-spec-text {
  padding: 50px 0 0 0;
  font-size: $cf-comm-text;
  line-height: $cf-comm-line-height;
  font-weight: 100;
  letter-spacing: 1px;
}

/*
h1,
h2,
h3 {
  font-family: "Be Vietnam Pro";
}

h4,
h5 {
  font-family: "Inter";
}
*/

@media screen and (max-width: 600px) {
  body {
    font-size: $cf-comm-text-mobile;
  }

  .paragraph-spec-text {
    font-size: $cf-mobile-text-size-small;
  }
}
